<template>
	<b-container class="pb-4 page">
		<b-row>
			<b-col>
				<h2 class="d-flex justify-content-center mt-5">정산금액</h2>
			</b-col>
		</b-row>
		<div class="d-flex justify-content-end mt-2">
			<b-button size="sm" @click="$router.push({ name: 'report-form' })"
				>식비 or N빵 입력</b-button
			>
		</div>
		<div v-for="(data, index) in dataList" :key="index">
			<b-card no-body class="mt-3" header-tag="header">
				<template #header>
					<div class="d-flex justify-content-between">
						<div class="d-flex align-items-center">
							<span style="margin-right: 0.5em">{{ data.title }}</span>
							<b-iconstack
								v-if="data.writer == currentUser.name"
								font-scale="1.2"
								class="mx-2 pointer"
								@click="
									$router.push({
										name: 'reports-update',
										params: { id: max(data), data: handleProps(data) },
									})
								"
							>
								<b-icon
									icon="circle"
									scale="1.3"
									style="color: rgb(13, 40, 82)"
								></b-icon>
								<b-icon
									icon="pencil"
									scale="0.8"
									style="color: rgb(13, 40, 82)"
								></b-icon>
							</b-iconstack>
							<b-iconstack v-else font-scale="1.2" class="mx-2">
								<b-icon icon="circle" scale="1.3" variant="secondary"></b-icon>
								<b-icon icon="pencil" scale="0.8" variant="secondary"></b-icon>
							</b-iconstack>
							<b-iconstack
								@click="handleModal(data.data[0])"
								class="mx-2 pointer"
								font-scale="1.2"
							>
								<b-icon
									icon="circle"
									scale="1.3"
									style="color: rgb(13, 40, 82)"
								></b-icon>
								<b-icon
									icon="file-text"
									scale="0.8"
									style="color: rgb(13, 40, 82)"
								></b-icon>
							</b-iconstack>
						</div>
						<div class="d-flex align-items-center">
							<div><b-icon icon="person-fill"></b-icon>{{ data.writer }}</div>
						</div>
					</div>
				</template>
				<b-tabs pills card>
					<b-tab
						v-for="report in data.data"
						:key="report.id"
						:title="tapTitle(report.created_at)"
						><b-card-text
							><b-table :items="report.detail" :fields="tableFields"></b-table>
							<b-icon
								icon="bookmark-check-fill"
								style="position: absolute; color: rgb(13, 40, 82)"
							></b-icon>
							<div class="memo" v-html="handleMemo(report.memo)"></div>
							<div class="d-flex justify-content-end mt-2">
								<span>누구의 잘못인가???</span>&nbsp;
								<b-icon
									icon="arrow-right-square-fill"
									:variant="historyBtnColor(report)"
									class="h5 pointer"
									@click="clickHistory(report)"
								/>
							</div>
						</b-card-text>
					</b-tab>
				</b-tabs>
			</b-card>
		</div>
		<receiptModal
			@close="closeModal"
			:receipt="receipt"
			:noReceipt="noReceipt"
			:isReceipt="isReceipt"
		></receiptModal>
		<div v-if="!page" style="margin-top: 2em">
			<div class="d-flex justify-content-center">더이상 데이터가 없습니다.</div>
		</div>
		<div v-else>
			<b-button
				@click="getData()"
				class="mt-3 pointer"
				style="
					width: 100%;
					background-color: #fff !important;
					color: rgb(13, 40, 82);
				"
				>더보기</b-button
			>
		</div>
	</b-container>
</template>
<script>
import { mapGetters } from "vuex"
import ApiService from "../common/api.service"
import receiptModal from "./layouts/Modal.vue"
import { consoleLog, errorLog, modalErr } from "../common/utils"
import API_URL from "../common/config"

export default {
	components: { receiptModal },
	created() {
		consoleLog("report page start")
		this.getData()
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	data() {
		return {
			tableFields: [
				{ key: "user_name", label: "이름" },
				{ key: "pre_balance", label: "이전정산" },
				{ key: "cost", label: "식비" },
				{ key: "delivery_cost", label: "배달비" },
				{ key: "balance", label: "정산" },
			],
			page: 1,
			perPage: 5,
			dataList: [],
			mainList: [],
			receipt: "",
			noReceipt: "",
			isReceipt: true,
		}
	},
	methods: {
		getData() {
			ApiService.query("reports", {
				params: { page: this.page, per_page: this.perPage },
			})
				.then(({ data }) => {
					consoleLog("report response data: ", data)
					this.page = data.next
					let results = data["results"]
					// report 별로 map 돌려서 그 안에서 item(user) 별로 map 돌려서 수정
					results.data.map((item) => {
						item.detail.map((row) => {
							row.balance = row.pre_balance + row.cost + row.delivery_cost
						})
						// 합계 행 추가
						const sumObj = { user_name: "합계" }
						sumObj.pre_balance = item.detail.reduce(function (acc, cur) {
							return acc + cur.pre_balance
						}, 0)
						sumObj.cost = item.detail.reduce(function (acc, cur) {
							return acc + cur.cost
						}, 0)
						sumObj.delivery_cost = item.detail.reduce(function (acc, cur) {
							return acc + cur.delivery_cost
						}, 0)
						sumObj.balance = item.detail.reduce(function (acc, cur) {
							return acc + cur.balance
						}, 0)
						item.detail.push(sumObj)
						// 천 단위마다 쉼표 추가
						item.detail.map((row) => {
							row.cost = row.cost.toLocaleString("ko-KR")
							row.balance = row.balance.toLocaleString("ko-KR")
							row.delivery_cost = row.delivery_cost.toLocaleString("ko-KR")
							row.pre_balance = row.pre_balance.toLocaleString("ko-KR")
						})
					})
					let tempIds = []
					for (const idList of results.ids) {
						tempIds.push(idList.filter((e) => e !== 1))
					}
					for (const idLi of tempIds) {
						let temp = {}
						let tempLi = []
						for (const id of idLi) {
							tempLi = tempLi.concat(
								results.data.filter((data) => data.id === id)
							)
						}
						let createReport = results.data.find(
							(report) => report.id === Math.max(...idLi)
						)
						let firstCreateReport = results.data.find(
							(report) => report.id === Math.min(...idLi)
						)
						temp.title = this.title(
							firstCreateReport.created_at,
							createReport.type,
							createReport.type_detail
						)
						temp.writer = createReport.writer_name
						temp.data = tempLi
						this.dataList.push(temp)
						this.noData = false
					}
					consoleLog("data list : ", this.dataList)
				})
				.catch((response) => errorLog(response))
		},
		// report title
		title(val, type, typeDetail) {
			const date = this.$dayjs(val)
			const reportType = type !== "기타" ? type : typeDetail
			return (
				String(date.get("M") + 1) +
				"월 " +
				String(date.get("D")) +
				"일 " +
				reportType
			)
		},
		handleMemo(memo) {
			return memo.split("\n").join("<br />")
		},
		tapTitle(val) {
			const date = this.$dayjs(val)
			return String(date.get("M") + 1) + "월" + String(date.get("D")) + "일"
		},
		// report form router props id
		max(val) {
			let dataLi = this.dataList.find((data) => data == val)
			let data = dataLi.data.map((data) => data.id)
			return Math.max(...data)
		},
		// report form router props data
		handleProps(data) {
			return data.data.find((i) => i.id == this.max(data))
		},
		// 모달창 핸들링
		handleModal(data) {
			if (data.receipt !== "" && data.receipt !== null) {
				this.isReceipt = true
				this.receipt = API_URL + data.receipt
			} else {
				this.isReceipt = false
				this.noReceipt = data.no_receipt
			}
			this.$bvModal.show("modal")
		},
		closeModal() {
			this.$bvModal.hide("modal")
		},
		// 정산 히스토리 버튼 컬러 핸들링
		historyBtnColor(report) {
			// warning, secondary
			for (const data of this.dataList) {
				let pre = data.data.filter((d) => d.id == report.effect_report_id)
				if (pre.length > 0) {
					if (
						pre[0].root_report_id == report.root_report_id ||
						pre[0].id == report.root_report_id
					) {
						return "secondary"
					} else {
						return "warning"
					}
				} else {
					continue
				}
			}
			if (report.root_report_id == null) {
				return "secondary"
			}
		},
		// 정산 히스토리 버튼 핸들링
		clickHistory(report) {
			let historyColor = this.historyBtnColor(report)
			if (historyColor == "secondary" && report.root_report_id == null) {
				this.showMsgBoxOk("영향을 받은 리포트가 없습니다.")
			} else if (historyColor == "secondary") {
				this.showMsgBoxOk("영향을 받은 리포트가 현재 정산내역에 존재합니다.")
			} else {
				this.$router.push({ name: "reports-history", params: { data: report } })
			}
		},
		// 알림창
		showMsgBoxOk(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
				})
				.catch((err) => {
					modalErr(err)
				})
		},
	},
}
</script>
<style>
.nav-pills .nav-link.active {
	background-color: grey;
}
.nav-link,
.nav-link:hover {
	color: grey;
}
.memo {
	font-size: 0.8rem;
	padding: 5%;
	border: 1px solid rgba(13, 40, 82, 0.25);
	border-radius: 0.25rem;
	position: relative;
}
th,
td {
	text-align: right;
}
hr {
	height: 1px;
	background-color: #ccc;
	border: none;
}
.pointer {
	cursor: pointer;
}
</style>
