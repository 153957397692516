<template>
	<b-modal id="modal" centered title="영수증">
		<template #modal-header>
			<span class="align-middle">영수증</span>
			<b-icon
				icon="x-square-fill"
				@click="$emit('close')"
				class="h3"
				shift-v="-4"
			></b-icon>
		</template>
		<p class="my-4">
			<b-img v-if="isReceipt" :src="receipt" fluid />
			<span v-else>{{ noReceipt }}</span>
		</p>
		<template #modal-footer>
			<div>
				<b-button size="sm" class="float-right" @click="$emit('close')">
					확인
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	props: ["receipt", "noReceipt", "isReceipt"],
	data() {
		return {}
	},
}
</script>
